<template>
    <v-container>
        <v-row>
            <v-col>
                <v-dialog
                v-model="dialog"
                width="400"
                
                transition="dialog-bottom-transition"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon>زیادکرنی جۆر</v-btn>
                </template>
                <v-card>
                    <v-toolbar
                    dark
                    outlined
                    :class="isUpdate?'orange accent-2':'blue accent-2'"
                    elevation="1"
                    >
                    <v-btn
                        icon
                        
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn  text @click="isUpdate? update():create()" >
                        <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی جۆر': 'زیادکردنی جۆر'}} </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-form ref="category" @submit.prevent>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12" lg="12" sm="12"><v-text-field @focus="$event.target.select()" label="ناوی جۆر" dense outlined v-model="category.category_name" :rules="rules.categoryName" @keydown.enter="create()"></v-text-field></v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    
                </v-card>
                </v-dialog>
               <v-card class="rounded-lg px-1 py-1">
                   
                  <v-data-table :headers="headers" :items="categories" hide-default-footer  :items-per-page="20" >
                      <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="category = {...item}; dialog = true" >mdi-pencil</v-icon
                            >
                            <v-icon large class="ml-2" v-else
                                >mdi-pencil</v-icon
                            >
                            </v-hover>
                            
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.category_id)" >mdi-delete</v-icon
                            >
                            <v-icon large class="ml-2" v-else v-bind="item">mdi-delete</v-icon
                            >
                            </v-hover>
                        </template>
                  </v-data-table>
                  <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfCategory/ 20)"  v-model="table.page"></v-pagination>
              </v-card> 
            </v-col>
        </v-row>
        <notification/>
    </v-container>
</template>

<script>
import notification from '../../components/notification.vue'
export default {
  components: { notification },
    data() {
        return {
            categories: [],
            table: {
                page: 1,
                numberOfCategory: 0,
            },
            dialog: false,
            category:{
                category_id: 0,
                category_name: ''
            },

            headers: [
                {text: 'ناوی جۆر', value: 'category_name', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                categoryName: [
                    (v) => {
                        return ( v != '' || 'تکایە ناوی جۆر بنووسە')
                    }
                ],
            }
        }
    },
    computed: {
        isUpdate(){
            return this.category.category_id
        }
    },
    created() {
        this.getCategories()
    },
    methods: {
        async getCategories(){
            try {
                const {data} = await this.axios.get('/expense_category', {params: {page: this.table.page}})
                this.categories = data.expense_categories
                this.table.numberOfCategory = data.count
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        },
        async create(){
            if (this.$refs.category.validate()) {
                try {
                    await this.axios.post('/expense_category', this.category)
                    this.getCategories()
                    this.reset()
                } catch (error) {
                    
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        async update(){
            if (this.$refs.category.validate()) {
                try {
                    await this.axios.patch('/expense_category/'+ this.category.category_id,this.category)
                    this.getCategories()
                    this.reset()
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        async remove(id){
            this.$confirm('ئایا دڵنیایی لە سڕینەوەی ئەم جۆرە؟', 'دڵنیابوونەوە', 'question').then(async () => {
            try {
                await this.axios.delete('/expense_category/'+id)
                this.getCategories()
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        })
        },
        reset(){
            this.category.category_id = 0
            this.category.category_name = ''
            this.$refs.category.resetValidation();
        }
    }
}
</script>

<style>

</style>